import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Header from "../Header";
import Seo from "../seo";
import ModalFooterCookies from "./ModalFooterCookies";
import { theme } from "../../themeV2";

function LandingLayout({children, seoTitle, seoDescription, seoNoIndex}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ModalFooterCookies />
      <Seo title={seoTitle} description={seoDescription} noIndex={seoNoIndex} />
      <Header />
      {children}
    </ThemeProvider>
  );
}

export default LandingLayout;
